type Config = {
  [key: string]: {
    Auth: {
      mandatorySignIn: boolean;
      region?: string;
      userPoolId?: string;
      userPoolWebClientId?: string;
    };
    endpoint?: string;
    mtdUrl?: string;
  };
};

export const STAGE = process.env.REACT_APP_ENV || 'development';
export const config: Config = {
  development: {
    Auth: {
      mandatorySignIn: true,
      region: process.env.REACT_APP_REGION,
      userPoolId: process.env.REACT_APP_USER_POOL_ID,
      userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
    },
    endpoint: process.env.REACT_APP_ENDPOINT,
    mtdUrl: process.env.REACT_APP_MTD_URL,
  },
  staging: {
    Auth: {
      mandatorySignIn: true,
      region: 'ap-northeast-1',
      userPoolId: 'ap-northeast-1_OCZtVM8qf',
      userPoolWebClientId: '5jc9a2obr98ir1enumckmnarvt',
    },
    endpoint: 'https://api-misumi-stg.translation-dashboard.com/api/v1',
    mtdUrl: 'https://misumi-stg.translation-dashboard.com',
  },
  'staging-v3': {
    Auth: {
      mandatorySignIn: true,
      region: 'ap-northeast-1',
      userPoolId: 'ap-northeast-1_RuLKZuK7l',
      userPoolWebClientId: '6j5k3sc1q2a6mhmfne832vanpp',
    },
    endpoint: 'https://mefxdoh40e.execute-api.ap-northeast-1.amazonaws.com/stg-v3/api/v1',
    mtdUrl: 'https://misumi-stg-v3.translation-dashboard.com',
  },
  production: {
    Auth: {
      mandatorySignIn: true,
      region: 'ap-northeast-1',
      userPoolId: 'ap-northeast-1_vnwFTLmKg',
      userPoolWebClientId: '4rddbipepuhb0s8pfbmmfrtf29',
    },
    endpoint: 'https://api-misumi.translation-dashboard.com/api/v1',
    mtdUrl: 'https://misumi.translation-dashboard.com',
  },
};
